<template>
	<div>
		<!-- <BlockUI :message="defaultMsg" :html="html" v-if="isLoading"></BlockUI> -->
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="4">
						<h5>Client Trucker Rates ({{count}})</h5>
			</CCol>
	     		 <CCol lg="8">
					  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('client_trucker_rates').create"
						size="sm" style="margin-right: 10px" 
						color="success" @click="toggleModal()" 
						class="float-lg-right"><i class="fa fa-plus"></i>Add Client Trucker Rate
					</CButton>

					<CButton 
						v-c-tooltip="'Download in CSV format. Faster download, no row styling.'"
						size="sm" style="margin-right: 10px" 
						color="primary" @click="download('csv')" 
						class="float-lg-right"><i class="fa fa-download"></i>Download (.csv) 
					</CButton>

					<CButton 
						v-c-tooltip="'Download in XLSX format. May take longer time to download.'"
						size="sm" style="margin-right: 10px" 
						color="primary" @click="download('xlsx')" 
						class="float-lg-right"><i class="fa fa-download"></i>Download (.xlsx)
						
					</CButton>
					
				

					<CButton 
						ref="uploadbtn"
						size="sm" style="margin-right: 10px" 
						color="warning" @click="showUploadModal = true" 
						class="float-lg-right"><i class="fa fa-upload"></i>Upload
					</CButton>

                    <CButton 
                        ref="deletebtn"
                        v-if="selected.length"
						size="sm" style="margin-right: 10px" 
						color="danger" @click="deleteMultiple()" 
						class="float-lg-right"><i class="fa fa-trash"></i>Delete
					</CButton>
		      		
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<br>
		<!-- <hr> -->

		<CRow>			
	      	 <CCol lg="12" class="text-center" v-if="isLoading"> 
				  <div class="spinner-border m-5" role="status">
						<span class="sr-only">Loading...</span>
				</div>
			  </CCol>
		<CCol lg="12" v-else>  
			<div class="table-responsive-sm table-responsive-md">
               
                <table class="table table-outlined table-striped table-hover"> 
                    <thead>
                        <tr>
                            <th class="p-2" style="width:35px">
                                <input :disabled="dataList.data.length == 0" class="form-control" v-model="selectAll" @click="select()" type="checkbox" />
                            </th>
                            <th v-for="h in fields" :key="h.key">
								<template v-if="h.key == 'destination_id_label'">
									<a href="javascript:void(0)" @click="sortColumn(h.key)"> {{ h.label}}</a> 
									<span v-show="sort">
										<i v-show="sort == 'desc'" class="fa fa-caret-down ml-2" aria-hidden="true"></i>
										<i v-show="sort == 'asc'" class="fa fa-caret-up ml-2" aria-hidden="true"></i>
									</span>
									
								</template>
								<template v-else>{{ h.label }}</template>
								
							</th>
                            <th style="width:30px" class="text-center pt-2">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="dataList.data.length">
                        <tr v-for="data in dataList.data" :key="data.id">
                            <td class="p-2">
                                <input :value="data.id" v-model="selected" class="form-control" type="checkbox" />
                            </td>
                            <td v-for="h in fields" :key="h.key+'data'">
                                {{ data[h.key] }}
                            </td>
                            <td class="text-center pt-2">
                                <CDropdown 
									:disabled="sort != null"
                                    toggler-text="Select"  
                                    size="sm"
                                    color="info"
                                >
                                    <CDropdownItem v-if="config.getPermission('client_trucker_rates').view" @click="viewModal(data)"><i class="fa fa-eye"> View </i></CDropdownItem> 
                                    <CDropdownItem v-if="config.getPermission('client_trucker_rates').update" @click="updateModal(data)"><i class="fa fa-pencil-square-o"> Edit </i></CDropdownItem>
                                    <CDropdownItem v-if="config.getPermission('client_trucker_rates').delete" @click="deleteRow(data)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
                                </CDropdown>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="11" class="text-center">
                                <p class="mb-0">No records found.</p>
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / limit)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        />
			</div>
	      	</CCol>
		  
	    </CRow>
	    <CModal :title="title" :show.sync="formModal" color="info" size="lg">
           	<form @submit.prevent="submit">
			
           		<CRow>
					<CCol lg="12">
						<div class="form-group"> 
							<label class="mb-1">Client Name <span class="text-danger">*</span></label>
							<v-select 
								label="customer_name" 
								:options="customerList.data"
								:reduce="item => item.id"
								v-model="dataParams.customer_id"
								placeholder="-Select-"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.customer_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="6">
						<div class="form-group"> 
							<label class="mb-1">Rate Type <span class="text-danger">*</span></label>
							<v-select 
								label="label" 
								:options="[
									{
										label: 'Special',
										value: 'special'
									},
									{
										label: 'Standard',
										value: 'standard'
									}
								]"
								:reduce="item => item.value"
								v-model="dataParams.rate_type"
								placeholder="-Select-"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.rate_type"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol>
					<CCol lg="6">
						<div class="form-group"> 
							<label class="mb-1">Depot <span class="text-danger">*</span></label>
							<v-select 
								:disabled="depot_id != null"
								label="setting_name" 
								:options="depotList.data"
								:reduce="item => item.id"
								v-model="dataParams.depot_id"
								placeholder="-Select-"
							> 
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.depot_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="6">
						<div class="form-group"> 
							<label class="mb-1">Commodity Type <span class="text-danger">*</span></label>
							<v-select 
								label="setting_name" 
								:options="commodityTypeList.data"
								:reduce="item => item.id"
								v-model="dataParams.commodity_id"
								placeholder="-Select-"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.commodity_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol>
					<CCol lg="6">
						<div class="form-group"> 
							<label class="mb-1">Truck Type <span class="text-danger">*</span></label>
							<v-select 
								label="setting_name" 
								:options="vehicleTypeList.data"
								:reduce="item => item.id"
								v-model="dataParams.vehicle_type_id"
								placeholder="-Select-"
							> 
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.vehicle_type_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="12">
						<div class="form-group"> 
							<label class="mb-1">Origin <span class="text-danger">*</span></label>
							<v-select 
								label="setting_name" 
								:options="originList.data"
								:reduce="item => item.id"
								v-model="dataParams.origin_id"
								placeholder="-Select-"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.origin_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol>
				</CRow>
				<CRow>
					<CCol lg="12">
						<div class="form-group"> 
							<label class="mb-1">Destination <span class="text-danger">*</span></label>
							<v-select 
								label="setting_name" 
								:options="destinationList.data"
								:reduce="item => item.id"
								v-model="dataParams.destination_id"
								placeholder="-Select-"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.destination_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol>
				</CRow> 
				<CRow>
					<CCol lg="6">
						<label class="mb-1">Client Rate <span class="text-danger">*</span></label>
						<CInput
						@keypress="isNumber($event)"
	
		                  v-model="dataParams.client_rate"
		                  required="true"
		                />
					</CCol>
					<CCol lg="6">
						<CInput
		                  label="Subcon Rate"
						  @keypress="isNumber($event)"
		                 
		                  v-model="dataParams.subcon_rate" 
		                
		                />
					</CCol>
				</CRow> 
			 
				
	            <div slot="footer" class="w-100">
					 <CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton>
	            	<CButton size="sm" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	               
	                
	            </div>
			
           	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>

		<!-- VIEW CLIENT RATES DETAILS -->
        <CModal title="Client Rate Details" :show.sync="showViewModal" color="info" size="md">
        <div class="p-2">
			<!-- <CCard class="p-4"> -->
				
        		<CRow class="mb-2">
	        		<CCol lg="4">
	        			Client Name:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.customer_id_label}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Rate Type:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.rate_type ? dataParams.rate_type :"-"}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Depot:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.depot_id_label ? dataParams.depot_id_label :"-"}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Commodity:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.commodity_id_label}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Truck Type:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.vehicle_type_id_label ? dataParams.vehicle_type_id_label :"-"}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Origin:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.origin_id_label ? dataParams.origin_id_label :"-"}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Destination:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.destination_id_label ? dataParams.destination_id_label :"-"}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Client Rate:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.client_rate?  dataParams.client_rate :"-"}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Subcon Rate:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.subcon_rate ? dataParams.subcon_rate :"-"}}</strong>
	        		</CCol>
	        	</CRow>
			<!-- </CCard> -->
        	</div>
        	<div slot="footer" class="w-100">
            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="showViewModal = false">
                    Close
                </CButton> 
                
            </div>
        	
        </CModal>
        <CModal title="Upload Client Trucker Rates" :show.sync="showUploadModal" color="info" size="lg">
        <div class="p-3">
			<label> Upload the accomplished Rates Template here ...</label>
			<CCard class="p-4">
				<input type="file" id="fileUpload">
			</CCard>
        	</div>
        	<div slot="footer" class="w-100">
            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="showUploadModal = false">
                    Close
                </CButton> 
                <CButton style="border-radius: .2rem; color: white;" color="primary" class="ml-1 mr-1 float-right" @click="upload">
                    Upload
                </CButton> 
                
            </div>
			<hr>
			<CRow>
        	<CCol lg="12">
    		<label style="font-size:12px" class="text-danger "> Instructions</label>
    		<ol style="font-size:12px">
    		 
			
    		<li >Make sure to use the Rates Template. You may download the template 
				<a style="font-weight:600" href="javascript:void(0)" @click="downloadTemplate">here.</a></li>
		
    		<li >Please make sure the data inputted in excel are already added/existing in the system. 
			 </li>
    	
    		<li >Accomplish the template properly and review it before uploading. </li>
    	
    		<li>System Only allows up to 100 entries to be uploaded at a time. For more than 
				<a style="font-weight:600" >100</a>
				, upload it separately. </li>
    		
			</ol>
			</CCol>	
    	</CRow>
		
        	<div slot="footer" class="w-100"></div>
        </CModal>
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import vSelect from 'vue-select'
import XLSX, {read, utils, writeFile} from 'xlsx';
import 'vue-select/dist/vue-select.css';
import Loader from '../../../loader.mixin.js';
import '../../style.css';
// import xls from'../assets/Upload_Rates_Template.xlsx';

export default {
	mounted(){ 
		this.getData(); 
		this.getCustomerList();
		this.getCommodityTypeList();
		this.getDepotList();
		this.getVehicleTypeList();
		this.getOriginList();
		this.getDestinationList()

	},
	mixins: [Loader],
	data(){
		return{
			// itemNi: xls,
			count:0,
            limit : 20,
			title:'',
			config,
			isLoading: false,
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",
			depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id,
			dataParams: {
				commodity_id: "",
				depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
				vehicle_type_id: "",
				customer_id: "",
				origin_id: "",
				destination_id: "",
				rate_type: "",
				client_rate: "",
				subcon_rate: ""
			},
            selected: [],
		    selectAll: false,
			file: null,
			showUploadModal: false,
			sort: null,
			sort_click_counter: 0,
			fields: [
				
				// {
				// 	key: 'rate_type', 
				// 	label: 'Rate Type'
				// },
				{
					key: 'depot_id_label', 
					label: 'Depot'
				},
				{
					key: 'customer_id_label', 
					label: 'Client'
				},
				{
					key: 'origin_id_label', 
					label: 'Origin'
				},
				{
					key: 'destination_id_label', 
					label: 'Destination'
				},
				
				
				{
					key: 'vehicle_type_id_label', 
					label: 'Truck Type'
				},
				{
					key: 'commodity_id_label', 
					label: 'Commodity'
				},
				
				{
					key: 'client_rate', 
					label: 'Client Rate'
				},
				{
					key: 'subcon_rate_label', 
					label: 'Subcon Rate'
				},
				// {
				// 	key: 'action',
				// 	label: 'Action',
				// 	_style: { width: '20px'}
				// }
			],
			currentPage: 1,
			currentPageInactive: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				customer_id: "",
				booking_type_id: "",
				depot_id: "", 
			},
			customerList: {
				data: []
			},
			commodityTypeList: {
				data: []
			}, 
			depotList: {
				data: []
			},
			vehicleTypeList: {
				data: []
			},
			originList: {
				data: []
			},
			destinationList: {
				data: []
			}
		}
	},
	name: 'Tables',
	components: {vSelect, Search},
    watch : {
        selected: {
            handler : function(val) {
                this.$nextTick(() => {
                    if(this.$refs.deletebtn)
                        this.$refs.deletebtn.focus()
                    else
                       this.$refs.uploadbtn.focus();
                })
            },
            deep : true
        }
    },
	methods: {
		sortColumn(key) {
			if(this.sort_click_counter == 0) {this.sort = 'asc'; this.sort_click_counter++;}
			else if(this.sort_click_counter == 1) {this.sort = 'desc'; this.sort_click_counter++;}
			else {
				this.sort = null;
				this.sort_click_counter = 0;
			}
			this.getData()
		},
        select() {
			this.selected = [];
			if (!this.selectAll) {
				for (let i in this.dataList.data) {
					this.selected.push(this.dataList.data[i].id);
				}
			}
		},
		updatePage(data){
			this.currentPage = data;
			this.getData();
		}, 

		updatePageInactive(data){
			this.currentPageInactive = data;
		}, 

	    toggleModal(){
	    	this.formModal = !this.formModal;
			this.title = "ADD CLIENT RATE";
	    },

	    submit(){
	    	var ax = {};

	    	if(this.dataParams.subcon_rate == ""){
	    		this.dataParams.subcon_rate = null;
	    	}

	    	if(this.dataParams.depot_id == ""){
	    		this.dataParams.depot_id = null;
	    	}
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/client_rate/"+this.selectedRow.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/client_rate", this.dataParams)
	    	}
	    	ax.then(response => {
	    		var text = 'Client rate successfully added!';
	    		if(this.editMode){
	    			text = 'Client rate successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					commodity_id: "",
					depot_id: "",
					vehicle_type_id: "",
					customer_id: "",
					origin_id: "",
					destination_id: "",
					rate_type: "",
					client_rate: "",
					subcon_rate: ""
				}
	    		this.getData();
	    	})
	    },

	    search(event){
	    	this.filter = event; 
	    	this.getData();
	    },

	    getData(){ 
			let vm = this;
			this.isLoading=true;

            this.selected = [];
	    	this.selectAll = false;
			let sort = this.sort ?? '';

	    	var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "all" || commodity_id == null){
	    		commodity_id = ""
	    	}

	    	var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}

	    	var vehicle_type_id = this.filter.vehicle_type_id;
	    	if(vehicle_type_id == "all" || vehicle_type_id == null){
	    		vehicle_type_id = ""
	    	}

	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	}

	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	}

	    	var destination_id = this.filter.destination_id;
	    	if(destination_id == "all" || destination_id == null){
	    		destination_id = ""
	    	}

	    	var rate_type = this.filter.rate_type;
	    	if(rate_type == "all" || rate_type == null){
	    		rate_type = ""
	    	}
	    	axios.get(config.api_path+'/client_rate?commodity_id='+commodity_id+'&depot_id='+depot_id+'&vehicle_type_id='+vehicle_type_id+'&customer_id='+customer_id+'&origin_id='+origin_id+'&destination_id='+destination_id+'&rate_type='+rate_type+'&page='+this.currentPage+'&limit='+this.limit+'&sort='+sort)
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{
					if(!vm.sort) {
						if(value.depot_id == null){
							value.depot_id_label = "N/A";
						}
						else{
							value.depot_id_label = value.depot.setting_name;
						}

						if(value.commodity_id == null){
							value.commodity_id_label = "N/A";
						}
						else{
							value.commodity_id_label = value.commodity_type.setting_name;
						}

						if(value.vehicle_type_id == null){
							value.vehicle_type_id_label = "N/A";
						}
						else{
							value.vehicle_type_id_label = value.vehicle_type.setting_name;
						}

						if(value.origin_id == null){
							value.origin_id_label = "N/A";
						}
						else{
							value.origin_id_label = value.origin.setting_name;
						}

						if(value.destination_id == null){
							value.destination_id_label = "N/A";
						}
						else{
							value.destination_id_label = value.destination.setting_name;
						}

						if(value.subcon_rate == null){
							value.subcon_rate_label = "N/A";
						}
						else{
							value.subcon_rate_label = value.subcon_rate;
						}

						if(value.customer_id == null){
							value.customer_id_label = "N/A";
						}
						else{
							value.customer_id_label = value.customer.customer_name;
						}

						value.rate_type = value.rate_type ?? 'N/A';

						
					}
					else {
						value.depot_id_label = value.depot ?? 'N/A';
						value.commodity_id_label = value.commodity_type ?? 'N/A';
						value.vehicle_type_id_label = value.vehicle_type ?? '';
						value.origin_id_label = value.origin ?? 'N/A';
						value.destination_id_label = value.destination ?? 'N/A';
						value.subcon_rate_label = value.subcon_rate ?? 'N/A';
						value.customer_id_label = value.customer_name ?? 'N/A';
					
						value.rate_type = value.rate_type ?? 'N/A';
					}
	    			return value;
	    		}); 
					this.count= response.data.total
	    		console.log(this.dataList.data)

				
	    	})
				.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    }, 

	    updateModal(item){
			this.title = "UPDATE CLIENT RATE";
	    	this.selectedRow = item;
	    	this.dataParams = item;
	    	this.formModal = true;
	    	this.editMode = true;

	    },

	    viewModal(item){

	    	this.selectedRow = item;
	    	this.dataParams = item;
	    	this.showViewModal = true; 

	    },

        deleteMultiple() {
            let vm = this;
            if(!vm.selected.length) return;

			let length = vm.selected.length;

            Swal.fire({
				icon:'warning',
				title: `You are about to delete ${vm.selected.length} ${vm.selected.length > 1 ? 'records' : 'record'}. Continue?`, 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
                cancelButtonText: 'No',
                cancelButtonColor : '#e55353'
			}).then((result) => { 
				if (result.isConfirmed) {
                    vm.isLoading = true;
					axios.delete(config.api_path+'/client_rate/delete/multiple', {
                        data : {
                            client_rate_ids : vm.selected
                        }
                    })
			    	.then(response => {
                        vm.isLoading = false;
			    		vm.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: `(${length}) ${length > 1 ? 'records' : 'record'} successfully deleted`,
							icon: 'success', 
						})
			    	}) 
                    .catch(err => {
                        vm.isLoading = false;
                    })
				}
			})  
        },
	    deleteRow(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this client rate?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/client_rate/'+item.id)
			    	.then(response => {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "Client rate successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    },

	    updateStatus(data){
	    	this.dataParams.status = data;
	    },

	       getCustomerList(){
	    	axios.get(config.api_path+'/reference/customer-list', {
				params: {
					show_all : true
				}
			})
	    	.then(response => {
	    		this.customerList = response.data;    		
	    	})
	    }, 

		//  getCommodityTypeList(){

        //   axios.get(config.api_path+'/reference/settings-list',{
        //     params:{
        //       setting_type:'commodity_type'
        //     }
        //   })
        //   .then(response => {
        //     this.commodityTypeList = response.data; 
        //     this.commodityTypeList.data.unshift({
        //       id: "all",
        //       setting_name: "All"
        //     })
        //   })

        // },

	    getCommodityTypeList(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'commodity_type'
            }
       		   })
	    	.then(response => {
	    		this.commodityTypeList = response.data; 
	    	})

	    },

	    getVehicleTypeList(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'vehicle_type'
            }
       		   })
	    	.then(response => {
	    		this.vehicleTypeList = response.data; 
	    	})

	    },

	    getDepotList(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'depot'
            }
       		   })
	    	.then(response => {
	    		this.depotList = response.data; 
	    	})

	    },

	    getOriginList(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'origin'
            }
       		   })
	    	.then(response => {
	    		this.originList = response.data; 
	    	})

	    },

	    getDestinationList(){

	    	axios.get(config.api_path+'/reference/settings-list',{
            params:{
              setting_type:'destination'
            }
       		   })
	    	.then(response => {
	    		this.destinationList = response.data; 
	    	})

	    },

	    updateVendorType(data){
	    	this.dataParams.vendor_type = data;
	    },

	    updateTaxType(data){
	    	this.dataParams.tax_type = data;
	    },

	    download(type){ 
	    	var commodity_id = this.filter.commodity_id;
	    	if(commodity_id == "all" || commodity_id == null){
	    		commodity_id = ""
	    	}

	    	var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}

	    	var vehicle_type_id = this.filter.vehicle_type_id;
	    	if(vehicle_type_id == "all" || vehicle_type_id == null){
	    		vehicle_type_id = ""
	    	}

	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	}

	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	}

	    	var destination_id = this.filter.destination_id;
	    	if(destination_id == "all" || destination_id == null){
	    		destination_id = ""
	    	}

	    	var rate_type = this.filter.rate_type;
	    	if(rate_type == "all" || rate_type == null){
	    		rate_type = ""
	    	}
			this.$showLoading(true);
	    	axios.get(config.api_path+'/reports/client_rate?commodity_id='+commodity_id+'&depot_id='+depot_id+'&vehicle_type_id='+vehicle_type_id+'&customer_id='+customer_id+'&origin_id='+origin_id+'&destination_id='+destination_id+'&rate_type='+rate_type+'&page='+this.currentPage+'&limit=20&report_type='+type)
	    	.then(response=>{
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
				this.$showLoading(false);

				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
	    	})
			.catch(err => {
				this.$showLoading(false);
			})
	    },

	    upload(){
			try {
				let vm = this;
				let reader = new FileReader();
				this.$showLoading(true);
				reader.onload = (e) => {
				let data = e.target.result;
				vm.isLoading = true;
				let workbook = read(data, {
					type: 'binary'
				});
				workbook.SheetNames.some((sheetName) => {
					// Here is my object ma homeboi
					var XL_row_object = utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
					
					// workaround to ensure correct excel column name
					let xl_row_holder = [];
					for (const [index, iterator] of XL_row_object.entries()) {
						let entry = {};
						for (const item in iterator) {
							// uppercase words
							let key = item.toLowerCase().replace(/\b[a-z]/g, function(letter) {
								return letter.toUpperCase();
							});
							key = key.trim();
							entry[key] = iterator[item] ? iterator[item]+''.trim(): iterator[item];
						}
						xl_row_holder[index] = entry;
					}
					// end

					// var json_object = JSON.stringify(XL_row_object)
					var json_object = JSON.stringify(xl_row_holder)
					this.importRates(JSON.parse(json_object))
					return true // break loop . read only first shit
				})
				}
				reader.onerror = function(ex) {
				console.log(ex);
				}
				reader.readAsBinaryString(document.getElementById('fileUpload').files[0])
			} catch (error) {
				this.$showLoading(false);
				Swal.fire({
					title: 'Upload Failed!',
					html : `
					<p>Invalid or no file selected.
					`,
					icon: 'error',
					confirmButtonText : 'Close'
				})
			}
			

	    },
	    importRates(json){
			this.$showLoading(true);
	    	axios.post(config.api_path+"/client_rate", json)
	    	.then(response=>{
				document.getElementById('fileUpload').value = null;
				Swal.fire({
					title: 'Upload Complete!',
					html : `
					<ul class="list-group list-group-flush">
						<li class="list-group-item d-flex justify-content-between align-items-center">
							Inserted
							<span class="badge badge-success badge-pill">${response.data.data.inserted_count}</span>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-center">
							Updated
							<span class="badge badge-warning badge-pill">${response.data.data.updated_count}</span>
						</li>
						<li class="list-group-item d-flex justify-content-between align-items-center">
							Failed
							<span class="badge badge-danger badge-pill">${response.data.data.error_count}</span>
						</li>
					</ul>
					`,
					icon: 'info',
					confirmButtonText : 'Close'
				})

	    		// if(response.data.data.error_count == 0){
	    		// 	Swal.fire({
				// 		title: 'Success!',
				// 		text: 'Successfully uploaded '+response.data.data.valid_count+' entries',
				// 		icon: 'success', 
				// 	})
	    		// }
	    		// else{
	    		// 	if(response.data.data.valid_count == 0){
	    		// 		Swal.fire({
				// 			title: 'Oh no!',
				// 			text: 'Upload failed with '+response.data.data.error_count+' entries. Please check if the data are already uploaded in the system',
				// 			icon: 'error', 
				// 		})	
	    		// 	}
	    		// 	else{
	    		// 		Swal.fire({
				// 			title: 'Success!',
				// 			text: 'Successfully uploaded '+response.data.data.valid_count+' entries but upload failed with '+response.data.data.error_count+" entries. Please check if the data are already uploaded in the system",
				// 			icon: 'success', 
				// 		})
	    		// 	}

					if(response.data.data.error_count > 0) {
						var wb = utils.book_new();
						var ws = utils.json_to_sheet(response.data.data.error_list);

						utils.book_append_sheet(wb, ws, "No Header");
						// wb.SheetNames.push("Failed Trucker Rates");
						wb.Sheets["Failed Trucker Rates"] = response.data.data.error_list;
						writeFile(wb, "Failed Trucker Rates.xlsx", {type: 'xlsx'})
					}
	    			

	    		// }
				this.$showLoading(false);
	    		this.getData(); 
	    		this.showUploadModal = false;
				
	    	}).catch(err => {
				this.$showLoading(false);
			});
	    },
	    downloadTemplate(){
	    	window.open(config.main_path.replace('/index.php', '')+'/Upload_Rates_Template.xlsx') 
	    }

 	}
}
</script>
